.vertical-divider {
  background: white;
  border-right: 4px solid #61daf9;
}

.input-icon-color {
  color: #00000040;
}

.d-flex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

/* .theme-color {
} */

.theme-background {
  background: white !important;
}

.text-white {
  color: #ffffff !important;
}
.text-bold {
  font-weight: bold !important;
}
.text-light {
  font-weight: 400 !important;
}
.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

/* Negative */

.mt-5-n {
  margin-top: -5px;
}
.mt-10-n {
  margin-top: -10px;
}
.mt-15-n {
  margin-top: -15px;
}
.mt-20-n {
  margin-top: -20px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}
.no-border {
  border: none !important;
}

.pull-right {
  display: block;
  float: right !important;
}

body {
  margin: 0 !important;
}
