/* Form Items */
.ant-form-vertical .ant-form-item-label {
  padding: 0 !important;
}
.ant-form-item {
  margin-bottom: 12px !important;
}
.filter-form .ant-form-item {
  margin-bottom: 0 !important;
}

/* Secondary Head Tabs */
.secondary-head-tabs .ant-tabs-nav::before {
  border-bottom: none !important;
}
.secondary-head-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  font-weight: 400;
}
.secondary-head-tabs .ant-tabs-tab:not(.ant-tabs-tab-active) .ant-tabs-tab-btn {
  color: whitesmoke;
}

/* Table Titles and Footers */
.ant-table-title,
.ant-table-footer {
  background: #f4f4f4;
  padding: 0 !important;
}

/* Page Title */
.page-title {
  font-weight: 400 !important;
  margin-top: 0 !important;
  margin-bottom: 8px !important;
}

/* System Config Tabs */
.system-config-tabs .ant-tabs-nav {
  margin: 0 !important;
}
.system-config-tabs .ant-tabs-tab {
  margin-left: 0 !important;
  background: #e0e0e0 !important;
}
.system-config-tabs .ant-tabs-tab.ant-tabs-tab-active {
  border-top: 2px solid #eeaa1e !important;
  background: #f4f4f4 !important;
}
.system-config-tabs .ant-card {
  background: #f4f4f4;
}
.system-config-tabs .ant-card-body {
  min-height: 400px;
  padding: 12px;
}

/* Batch Item Table */
.batch-item-table .red .highlight {
  background: #da1e271a !important;
  color: #b31920;
}
.batch-item-table .green .highlight {
  background: #24a04740 !important;
  color: #1c7d37;
}
.batch-item-table .voided > .ant-table-cell {
  background: #d5d5d5 !important;
}
.batch-item-table .labor-missing > .ant-table-cell {
  background: #fcd6d7 !important;
}

/* Row Dragging */
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}
.row-dragging td {
  padding: 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}

/* Ellipsis Typography */
.ant-typography.ant-typography-ellipsis {
  margin-bottom: 0;
}

/* Data Table Cells */
.data-table td,
.data-table th {
  padding: 4px 8px !important;
}

.data-table .ant-select input {
  display: none !important;
}

.data-table .table-inactive-row .ant-table-cell {
  background: rgb(200, 200, 200) !important;
}
/* Right Aligned Inputs */
input.right-aligned {
  text-align: right;
}
.ant-input-number.right-aligned input.ant-input-number-input {
  text-align: right;
  padding: 0 25px 0 11px;
}

/* Full Height Table */
.full-height-table .ant-table-body {
  min-height: calc(100vh - 500px);
}

/* Agreement Modal */
.agreement-modal .ant-modal-close-x {
  color: #fff;
}
.agreement-modal .ant-modal-content {
  background-color: rgba(0, 0, 0, 0.85) !important;
}
.agreement-modal .ant-modal-header {
  background-color: transparent;
}
.agreement-modal .ant-modal-title {
  color: #fff;
}
.agreement-modal .ant-modal-title .agreement-title {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.agreement-modal .ant-modal-title .agreement-image {
  width: 150px;
  height: 40px;
}
.agreement-modal .ant-modal-title .agreement-image img {
  max-width: 100%;
  max-height: 100%;
}
.agreement-modal .ant-modal-body {
  background-color: #fff;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 7px;
  width: 92%;
  margin-left: auto;
  margin-right: auto;
}
.table-child-row td,
.table-child-row .ant-table-cell,
.bad-cpn-modal thead th,
.ant-table-cell {
  padding: 8px 16px !important;
  max-height: 50px;
}
.table-child-row > .integration-table-first-col {
  border-left: 8px solid #d9d9d9;
  position: relative;
  left: 18px;
  width: calc(100% - 32px) !important;
  max-width: calc(100% - 32px) !important;
}
.distributor-quickbooks-status {
  color: red;
}
.classification-parent-tree-node .ant-select-tree-node-content-wrapper {
  cursor: default !important;
  background-color: transparent !important;
}
.release-version-tab .ant-card-body {
  padding: 12px !important;
}

.release-notes-side-panel {
  max-height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgb(254, 254, 254);
}

.release-notes-side-panel .ant-card-body {
  padding: 12px 18px !important;
}

.ant-pagination-options-quick-jumper > input {
  display: none !important;
}

.manufacturer-code-drawer-body {
  padding: 0 !important;
}

.manufacturer-code-drawer-body > div {
  height: 100%;
}
.custom-spin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}
.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100% !important;
}
