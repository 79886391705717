.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #eeaa1e !important;
}

.ant-btn-secondary,
.ant-btn-secondary:hover,
.ant-btn-secondary:visited,
.ant-btn-secondary:focus,
.ant-btn-secondary:disabled {
  background: #0f0708;
  color: white;
  border: none;
}

.btn-green,
.btn-green:hover,
.btn-green:visited,
.btn-green:focus,
.btn-green:disabled {
  background: #24a047;
  color: white;
  border: none;
}

.ant-switch-green.ant-switch-checked {
  background: #24a047;
}
/*Header style*/
.logo {
  /*background-color: rgb(2 21 41 / 28%) !important;*/
  border-bottom: 2px solid #ffffff54;
  margin: auto;
  display: block;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}

.ant-table-content {
  overflow-x: auto;
}

.edit-cataloge .ant-modal-header {
  padding-bottom: 2px !important;
}
.edit-cataloge .ant-modal-body {
  padding-top: 14px !important;
}

/* changing css design */
.ant-btn-primary,
.ant-btn-primary:hover {
  color: #fff !important;
  border-color: #eeaa1e;
  background: #eeaa1e !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab-active {
  color: #eeaa1e !important;
  text-shadow: 0 0 0.25px currentcolor !important;
}

.disabled-row {
  background-color: #dcdcdc;
  pointer-events: none;
}

.bad-cpn-modal .ant-modal-content {
  overflow-y: auto;
  max-height: calc(100vh - 50px);
}

.confirm-modal .ant-modal-content,
.confirm-modal button {
  border-radius: 2px;
}

.confirm-modal .ant-btn-default:hover {
  background: #ffffff !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.88) !important;
}

.tooltip .ant-tooltip-arrow {
  display: none;
}

.tooltip .ant-tooltip-content {
  margin-bottom: -10px;
}
